import {
  Container,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  makeStyles,
  Typography,
  Grid,
  Paper,
  Button,
  TextField,
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import React from 'react'
import Layout from '../components/layout'
import NewHeader from '../components/NewHeader'
import MembershipImage from '../images/membership.jpg'
import SEO from '../components/seo'
import '../components/layout.css'
import clsx from 'clsx'

const useStyles = makeStyles(theme => ({
  marginTop84: {
    marginTop: -120,
  },
  white: {
    color: '#FFF',
  },
  mt14: {
    marginTop: 14,
  },
  titleFont: {
    fontFamily: 'Volkhov',
  },
}))

const Membership = () => {
  const classes = useStyles()
  return (
    <div>
      <Layout>
        <SEO title="General Questions" />
        <NewHeader title="General Questions" image={MembershipImage} />
        <Container>
          <div className={classes.marginTop84}>
            <Typography
              className={clsx(classes.white, classes.titleFont)}
              variant="h4"
            >
              Frequently Asked Questions
            </Typography>
          </div>
          <Grid container spacing={2}>
            <Grid item md={9} xs={12}>
              <Paper style={{ marginTop: 24 }}>
                <div style={{ backgroundColor: '#3c5eab', padding: 6 }}></div>
                <ExpansionPanel>
                  <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography className={classes.heading} color="primary">
                      <b>
                        1. Lorem ipsum dolor sit amet, consectetur adipiscing
                        elit. Suspendisse malesuada lacus ex, sit amet blandit
                        leo lobortis eget
                      </b>
                    </Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <Typography>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Curabitur ut nisi ut velit semper rutrum vitae id tellus.
                      Curabitur condimentum sapien eu varius tempus. Sed cursus
                      odio cursus massa placerat tincidunt. Mauris lobortis
                      sapien nec ante posuere, vitae bibendum leo porttitor.
                      Etiam pulvinar lorem elit, faucibus accumsan neque
                      vulputate et.
                    </Typography>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
                <ExpansionPanel>
                  <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                  >
                    <Typography className={classes.heading} color="primary">
                      <b>
                        2. Pellentesque dui libero, pretium in luctus a, porta
                        in arcu. Mauris dictum semper nisi, ornare malesuada
                      </b>
                    </Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <Typography>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Curabitur ut nisi ut velit semper rutrum vitae id tellus.
                      Curabitur condimentum sapien eu varius tempus. Sed cursus
                      odio cursus massa placerat tincidunt. Mauris lobortis
                      sapien nec ante posuere, vitae bibendum leo porttitor.
                      Etiam pulvinar lorem elit, faucibus accumsan neque
                      vulputate et.
                    </Typography>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
                <ExpansionPanel>
                  <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                  >
                    <Typography className={classes.heading} color="primary">
                      <b>
                        3. Phasellus eget tincidunt felis. Sed nec enim at velit
                        accumsan efficitur in ut orci. Etiam bibendum, augue nec
                        rhoncus euismod
                      </b>
                    </Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <Typography>
                      Phasellus eget tincidunt felis. Sed nec enim at velit
                      accumsan efficitur in ut orci. Etiam bibendum, augue nec
                      rhoncus euismod, arcu ligula vestibulum erat, porta
                      elementum nisl arcu non sem. Donec ornare ornare neque nec
                      molestie
                    </Typography>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
                <ExpansionPanel>
                  <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                  >
                    <Typography className={classes.heading} color="primary">
                      <b>
                        4. Curabitur at leo vel purus aliquam placerat quis eu
                        nunc. Nullam condimentum congue nisl ac vehicula
                      </b>
                    </Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <Typography>
                      Curabitur at leo vel purus aliquam placerat quis eu nunc.
                      Nullam condimentum congue nisl ac vehicula. Donec velit
                      nisi, mattis quis scelerisque eget, sollicitudin id erat.
                      Morbi vitae risus justo. Nullam eu eleifend ex. Nam vel
                      nulla et erat finibus sodales. Nam ut diam eget urna
                      pellentesque interdum
                    </Typography>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
                <ExpansionPanel>
                  <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography className={classes.heading} color="primary">
                      <b>
                        5. Lorem ipsum dolor sit amet, consectetur adipiscing
                        elit. Suspendisse malesuada lacus ex, sit amet blandit
                        leo lobortis eget
                      </b>
                    </Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <Typography>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Curabitur ut nisi ut velit semper rutrum vitae id tellus.
                      Curabitur condimentum sapien eu varius tempus. Sed cursus
                      odio cursus massa placerat tincidunt. Mauris lobortis
                      sapien nec ante posuere, vitae bibendum leo porttitor.
                      Etiam pulvinar lorem elit, faucibus accumsan neque
                      vulputate et.
                    </Typography>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
                <ExpansionPanel>
                  <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                  >
                    <Typography className={classes.heading} color="primary">
                      <b>
                        6. Pellentesque dui libero, pretium in luctus a, porta
                        in arcu. Mauris dictum semper nisi, ornare malesuada
                      </b>
                    </Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <Typography>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Curabitur ut nisi ut velit semper rutrum vitae id tellus.
                      Curabitur condimentum sapien eu varius tempus. Sed cursus
                      odio cursus massa placerat tincidunt. Mauris lobortis
                      sapien nec ante posuere, vitae bibendum leo porttitor.
                      Etiam pulvinar lorem elit, faucibus accumsan neque
                      vulputate et.
                    </Typography>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
                <ExpansionPanel>
                  <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                  >
                    <Typography className={classes.heading} color="primary">
                      <b>
                        7. Phasellus eget tincidunt felis. Sed nec enim at velit
                        accumsan efficitur in ut orci. Etiam bibendum, augue nec
                        rhoncus euismod
                      </b>
                    </Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <Typography>
                      Phasellus eget tincidunt felis. Sed nec enim at velit
                      accumsan efficitur in ut orci. Etiam bibendum, augue nec
                      rhoncus euismod, arcu ligula vestibulum erat, porta
                      elementum nisl arcu non sem. Donec ornare ornare neque nec
                      molestie
                    </Typography>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
                <ExpansionPanel>
                  <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                  >
                    <Typography className={classes.heading} color="primary">
                      <b>
                        8. Curabitur at leo vel purus aliquam placerat quis eu
                        nunc. Nullam condimentum congue nisl ac vehicula
                      </b>
                    </Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <Typography>
                      Curabitur at leo vel purus aliquam placerat quis eu nunc.
                      Nullam condimentum congue nisl ac vehicula. Donec velit
                      nisi, mattis quis scelerisque eget, sollicitudin id erat.
                      Morbi vitae risus justo. Nullam eu eleifend ex. Nam vel
                      nulla et erat finibus sodales. Nam ut diam eget urna
                      pellentesque interdum
                    </Typography>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
              </Paper>
            </Grid>
            <Grid item md={3} xs={12}>
              <Paper style={{ marginTop: 24 }}>
                <div style={{ backgroundColor: '#b12229', padding: 6 }}></div>
                <div style={{ padding: 24 }}>
                  <Typography variant="h6" className={classes.titleFont}>Important</Typography>
                  <Typography variant="body2" className={classes.mt14}>
                    Have any question in your mind?
                  </Typography>

                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.mt14}
                  >
                    Ask Question
                  </Button>
                </div>
              </Paper>
            </Grid>
          </Grid>
        </Container>
        <div style={{ backgroundColor: '#efefef', marginTop: 84 }}>
          <Container style={{ padding: 54 }}>
            <Grid
              container
              spacing={2}
              alignItems="center"
              align="center"
              justify="center"
            >
              <Grid item md={12}>
                <Typography variant="h4" className={classes.titleFont}>
                  Subscribe to newsletter
                </Typography>
              </Grid>
              <Grid item md={5}>
                <Typography variant="body1">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Curabitur ut nisi ut velit semper rutrum vitae id tellus.
                  Curabitur condimentum sapien eu varius tempus
                </Typography>
              </Grid>
              <Grid item md={12}>
                <Grid item md={4}>
                  <TextField
                    className={classes.mt14}
                    fullWidth
                    id="outlined-basic"
                    label="Email"
                    variant="filled"
                  />
                  <Button
                    className={classes.mt14}
                    variant="contained"
                    color="primary"
                  >
                    Subscribe
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Container>
        </div>
      </Layout>
    </div>
  )
}

export default Membership
